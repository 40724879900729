<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row v-if="acceso">
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Retroalimentación de seguimiento</span>
	  		    <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small dark color="green"
              @click="exportExcel( retros  , 'retros_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="space-between">

	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<label>Selecciona el ciclo</label>
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                  single-line
                >
                </v-autocomplete>
	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<label>Fecha incio</label>
	              <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  v-model="fechaini"
                  single-line
                  type="date"
                ></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<label>Fecha final</label>
	              <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  v-model="fechafin"
                  single-line
                  type="date"
                ></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<label>Selecciona el concepto</label>
	              <v-select
                  :items="[{id: 1, concepto: 'NI'},{id: 2, concepto: 'RI'},{id: 3, concepto: 'TODOS'}]"
                  v-model="concepto"
                  label="Concepto"
                  item-text="concepto"
                  item-value="id"
                  filled
                  dense
                  single-line
                ></v-select>
	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<label>Selecciona el estatus</label>
	              <v-select
                  :items="[{id: 0, concepto: 'Asignado'},{id: 1, concepto: 'Aceptado'},{id: 2, concepto: 'Rechazado'},{id: 3, concepto: 'Todos'}]"
                  v-model="estatus"
                  label="estatus"
                  item-text="concepto"
                  item-value="id"
                  filled
                  dense
                  single-line
                ></v-select>
	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<label>Selecciona el usuario</label>
	              <v-autocomplete
                  :items="evaluados"
                  v-model="evaluado"
                  label="Evaluado"
                  item-text="evaluado"
                  item-value="id_evaluado"
                  filled
                  dense
                  single-line
                  clearable
                ></v-autocomplete>
	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>

	  		  	<label>Reporte</label>
	  		  	<v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headersReporte"
							    :items="reporte"
							    class="elevation-0"
							    :mobile-breakpoint="100"
			            dense
			            :items-per-page="10"
			            hide-default-footer
							  >
							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>

	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterRetros"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.estatus="{ item }">
							      <v-chip
							      	v-if="item.estatus == 1"
							      	small
							      	color="green"
							      	dark
							      >Aceptado</v-chip>

							      <v-chip
							      	v-if="item.estatus == 2"
							      	small
							      	color="black"
							      	dark
							      >Rechazado</v-chip>

							      <v-chip
							      	v-if="item.estatus == 0"
							      	small
							      	color="blue"
							      	dark
							      >Asignado</v-chip>
							    </template>

							    <template v-slot:item.concepto="{ item }">
							      <v-chip
							      	v-if="item.concepto == 1"
							      	small
							      	color="grey"
							      	dark
							      >NI</v-chip>

							      <v-chip
							      	v-if="item.concepto == 2"
							      	small
							      	color="orange"
							      	dark
							      >RI</v-chip>
							      
							    </template>


							    <template v-slot:item.actions="{ item }">
							    	<v-btn
							    		color="primary"
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn
							    		color="error"
							    		x-small
							    		@click="deleteItem(item)"
							    		v-if="getdatosUsuario.iderp == item.id_evalua"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="800px"
      persistent
      @keydown.ctrl="pegar"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idretroalimentacion }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
          	<v-col cols="12">
          		<label>Selecciona el concepto</label><br/>
	  		  		<label>Elige la opción que deseas evaluar si es un NI o es un RI</label>
              <v-radio-group
					      v-model="editedItem.concepto"
					      row
					      hide-details
					    >
					      <v-radio
					        label="NI"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="RI"
					        :value="2"
					      ></v-radio>
					    </v-radio-group>
            </v-col>

            <v-col cols="12">
          		<label>Selecciona el usuario a evaluar</label><br/>
	  		  		<label>Selecciona la persona que deseas evaluar y deberá ser responsable de los seguimiento de: {{ editedItem.concepto == 1 ? 'NI' : editedItem.concepto == 2 ? 'RI' : '' }}</label>
	  		  		<v-autocomplete
	  		  			filled
	  		  			dense
	  		  			clearable
	  		  			v-model="editedItem.id_usuario"
	  		  			:items="usuarios"
								label="Selecciona un usuario"
								hide-details
								item-text="datos_completos"
								item-value="id_usuario"
								:readonly="editedIndex >= 0 ? true : false"
							>
							</v-autocomplete>
            </v-col>

            <v-col cols="12" v-if="editedItem.concepto">
							<v-card class="shadowCard">
								<v-card-title class="text-subtitle-1">Bandeja de entrada de WhatsApp</v-card-title>
							  <v-card-text class="black--text">
							    <v-row>

										<v-col cols="12" md="6" class="mb-4">
          						<label class="grey--text darken-5">Toma una captura de pantalla de la bandeja de entrada y presiona Ctrl + V en este recuadro para pegarla.</label>
						          <v-file-input
										    label="Da clic para agregar una imagen"
										    filled
										    prepend-icon="mdi-camera"
										    v-model="editedItem.file"
										    @change="updateFoto2( )"
										  ></v-file-input>

						          <v-img :src="editedItem.url" v-if="editedItem.url" contain max-height="300"/>

						          <v-btn 
						          	v-if="editedItem.file"
						          	color="red" 
						          	dark 
						          	small 
						          	tile 
						          	@click="editedItem.file = null, editedItem.url = null"
						          	class="mt-4"
						          >
						          	<v-icon small left>mdi-delete</v-icon>
						        		Eliminar
						        	</v-btn>
						        	
										</v-col>

										<v-col cols="12" md="6">
          						<label class="grey--text darken-5">A continuación, solo elige la opción que mejor corresponda entre un SI y un NO.</label>
          						<br/>
          						<br/>
											<label >
												¿Cuenta con mensajes sin leer de <b>más de una hora?</b>
											</label>
											<v-radio-group
									      v-model="editedItem.p1"
									      row
									      dense
									      hide-details
									      class="mb-2 mt-0"
									    >
									      <v-radio
									      	class="pt-0"
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									      	class="pt-0"
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label >
												¿Cuenta con {{ editedItem.concepto == 1 ? 'prospectos' : 'alumnos' }} <b>en visto?</b>
											</label>
									    <v-radio-group
									      v-model="editedItem.p2"
									      row
									      dense
									      hide-details
									      class="pt-0 mt-0"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>
									    
											<v-textarea
												v-model="editedItem.p3"
												:rows="6"
												auto-grow
												filled
												dense
												label="Escribe una Retroalimentación"
											>
											</v-textarea>
										</v-col>
							    </v-row>
							  </v-card-text>
							</v-card>
						</v-col>

						<v-col cols="12" v-for="(contacto , i ) in contactos" :key="i" v-if="editedItem.concepto">
							<v-card class="shadowCard">
								<v-card-title class="text-subtitle-1">Contacto #{{ i + 1 }}</v-card-title>
							  <v-card-text>
							    <v-row>
				            <v-col cols="12">
	  		  						<label v-if="editedItem.concepto == 1">Elige el prospecto del cual vas a evaluar la información </label>
											<v-autocomplete
												 v-if="editedItem.concepto == 1"
							          filled 
							          dense
							          clearable
							          v-model="contacto.idprospectos"
							          :items="prospectos"
							          label="Selecciona el Prospecto"
							          hide-details
							          item-text="datos_completos"
							          item-value="idprospectos"
							          class="mt-4"
							        >
							        </v-autocomplete>

							        <label v-if="editedItem.concepto == 2">Selecciona el alumno del cual revisarás el seguimiento</label>
											<v-autocomplete
												v-if="editedItem.concepto == 2"
							          filled 
							          dense
							          clearable
							          v-model="contacto.id_alumno"
							          :items="alumnos"
							          label="Selecciona el alumno"
							          item-text="datos_completos"
							          item-value="id_alumno"
							          class="mt-4"
							        >
							        </v-autocomplete>

							        <label v-if="editedItem.concepto == 2">Ingresa el número de teléfono con el que se hizo el seguimiento </label>
											<v-text-field
												v-if="editedItem.concepto == 2"
											  filled
											  v-model="contacto.telefono"
											  dense
											  label="Teléfono"
											  hide-details
											></v-text-field>
										</v-col>

										<v-col cols="12" md="6" class="mb-4">
          						<label class="grey--text darken-5">Toma una captura de pantalla de la bandeja de entrada y presiona Ctrl + V en este recuadro para pegarla.</label>
						          <v-file-input
										    label="File input"
										    filled
										    prepend-icon="mdi-camera"
										    v-model="contacto.file"
										    @change="updateFoto( contacto )"
										  ></v-file-input>

						          <v-img :src="contacto.url" v-if="contacto.file" contain max-height="300"/>

						          <v-btn 
						          	v-if="contacto.file"
						          	color="red" 
						          	dark 
						          	small 
						          	tile 
						          	@click="contacto.file = null, contacto.url = null"
						          	class="mt-4"
						          >
						          	<v-icon small left>mdi-delete</v-icon>
						        		Eliminar
						        	</v-btn>
										</v-col>

										<v-col cols="12" md="6">
          						<label class="grey--text darken-5">A continuación, solo elige la opción que mejor corresponda entre un SI y un NO.</label><br/>
											<label v-if="editedItem.concepto == 1">¿Hay mensajes Robotizados?</label>
											<v-radio-group
												v-if="editedItem.concepto == 1"
									      v-model="contacto.valor1"
									      row
									      dense
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="editedItem.concepto == 1">¿El tiempo de respuesta entre el cliente y el asesor es adecuado?</label>
									    <v-radio-group
												v-if="editedItem.concepto == 1"
									      v-model="contacto.valor2"
									      row
									      dense
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="editedItem.concepto == 1">¿El asesor usa herramientas adicionales en su seguimiento como audios e imágenes?</label>
									    <v-radio-group
												v-if="editedItem.concepto == 1"
									      v-model="contacto.valor3"
									      row
									      dense
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="editedItem.concepto == 1">¿El asesor usa los documentos de la escuela de manera correcta (Plan de estudios, calendarios de pago , métodos de pago)?</label>
									    <v-radio-group
												v-if="editedItem.concepto == 1"
									      v-model="contacto.valor4"
									      row
									      dense
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="editedItem.concepto == 1">¿El asesor se dirige con formalidad al prospecto?</label>
									    <v-radio-group
												v-if="editedItem.concepto == 1"
									      v-model="contacto.valor5"
									      row
									      dense
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="editedItem.concepto == 2">¿Hay seguimientos Robotizados?</label>
									    <v-radio-group
												v-if="editedItem.concepto == 2"
									      v-model="contacto.valor6"
									      row
									      dense
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="editedItem.concepto == 2">¿El tiempo de respuesta entre el alumno y el asesor académico es adecuado? </label>
									    <v-radio-group
												v-if="editedItem.concepto == 2"
									      v-model="contacto.valor7"
									      row
									      dense
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="editedItem.concepto == 2">¿El asesor resuelve las situaciones y dudas del alumno?</label>
									    <v-radio-group
												v-if="editedItem.concepto == 2"
									      v-model="contacto.valor8"
									      row
									      dense
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="editedItem.concepto == 2">¿El asesor envia el recordatorio de pago en tiempo y forma?</label>
									    <v-radio-group
												v-if="editedItem.concepto == 2"
									      v-model="contacto.valor9"
									      row
									      dense
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="editedItem.concepto == 2">¿El asesor se dijere de manera formal y educada con el alumno?</label>
									    <v-radio-group
												v-if="editedItem.concepto == 2"
									      v-model="contacto.valor10"
									      row
									      dense
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>
									    
											<v-textarea
												v-model="contacto.retroalimentacion"
												:rows="6"
												auto-grow
												filled
												dense
												label="Escribe una Retroalimentación"
											>
											</v-textarea>
										</v-col>
							    </v-row>
							  </v-card-text>
							</v-card>
						</v-col>
           
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogEdit"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.id_plantel }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
          	<v-col cols="12">
          		<label>Selecciona el concepto</label>
              <v-radio-group
					      v-model="contacto.concepto"
					      row
					      hide-details
					      readonly
					    >
					      <v-radio
					        label="NI"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="RI"
					        :value="2"
					      ></v-radio>
					    </v-radio-group>
            </v-col>

            <v-col cols="12">
          		<label>Evaluó: </label><br/>
	  		  		<b>{{ contacto.evalua }}</b>
	  		  		<br/>
	  		  		<br/>

	  		  		<label>Evaluado: </label><br/>
	  		  		<b>{{ contacto.evaluado }}</b>
	  		  		<br/>
	  		  		<br/>

	  		  		<label>Promedio: </label><br/>
              <b>{{ contacto.promedio }}</b>
              <br/>
              <br/>

              <label>Estatus: </label><br/>
              <v-chip v-if="contacto.estatus == 0" small dark color="blue">Asignado</v-chip>
              <v-chip v-if="contacto.estatus == 1" small dark color="green">Aceptado</v-chip>
              <v-chip v-if="contacto.estatus == 2" small dark color="black">Rechazado</v-chip>
              <br/>
              <br/>

              <label>Mensaje Rechazo: </label><br/>
              <b>{{ contacto.mensaje_rechazo }}</b>
              <br/>
              <br/>
            </v-col>

            <v-col cols="12">
							<v-card class="shadowCard">
								<v-card-title class="text-subtitle-1">Bandeja de entrada de WhatsApp</v-card-title>
							  <v-card-text class="black--text">
							    <v-row>

										<v-col cols="12" md="6" class="mb-4">
						          <v-img :src="url + contacto.foto" contain max-height="300"/>
										</v-col>

										<v-col cols="12" md="6">
          						<label class="grey--text darken-5">A continuación, solo elige la opción que mejor corresponda entre un SI y un NO.</label>
          						<br/>
          						<br/>
											<label v-if="contacto.concepto == 1">
												¿Cuenta con mensajes sin leer de <b>más de una hora?</b>
											</label>
											<v-radio-group
												v-if="contacto.concepto == 1"
									      v-model="contacto.p1"
									      row
									      dense
									      hide-details
					     				  readonly
									      class="mb-2 mt-0"
									    >
									      <v-radio
									      	class="pt-0"
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									      	class="pt-0"
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="contacto.concepto == 1">
												¿Cuenta con {{ contacto.concepto == 1 ? 'prospectos' : 'alumnos' }} <b>en visto?</b>
											</label>
									    <v-radio-group
												v-if="contacto.concepto == 1"
									      v-model="contacto.p2"
									      row
									      dense
									      readonly
									      hide-details
									      class="pt-0 mt-0"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>
									    
											<v-textarea
												v-model="contacto.p3"
												:rows="6"
												auto-grow
												filled
												dense
												readonly
												label="Escribe una Retroalimentación"
											>
											</v-textarea>
										</v-col>
							    </v-row>
							  </v-card-text>
							</v-card>
						</v-col>

						<v-col cols="12" v-for="(folios , i ) in contacto.contactos" :key="i">
							<v-card class="shadowCard">
								<v-card-title class="text-subtitle-1">Contacto #{{ i + 1 }}</v-card-title>
							  <v-card-text>
							    <v-row>
				            <v-col cols="12">
											<div v-if="contacto.concepto == 1"><b>Folio:</b> {{ folios.folio }}</div><br/>
											<div v-if="contacto.concepto == 2"><b>Alumno:</b> {{ folios.alumno }}</div><br/>
											<b>Telefono:</b> {{ folios.telefono }}<br/>
										</v-col>

										<v-col cols="12" md="6" class="mb-4">
						          <v-img :src="url + folios.foto" contain aspect-ratio="2" @click="verImagen( folios.foto )" />
										</v-col>


										<v-col cols="12" md="6">
											<label v-if="contacto.concepto == 1">¿Hay mensajes Robotizados?</label>
											<v-radio-group
												v-if="contacto.concepto == 1"
									      v-model="folios.valor1"
									      row
									      dense
									      hide-details
									      readonly
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="contacto.concepto == 1">¿El tiempo de respuesta entre el cliente y el asesor es adecuado?</label>
									    <v-radio-group
												v-if="contacto.concepto == 1"
									      v-model="folios.valor2"
									      row
									      dense
									      readonly
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="contacto.concepto == 1">¿El asesor usa herramientas adicionales en su seguimiento como audios e imágenes?</label>
									    <v-radio-group
												v-if="contacto.concepto == 1"
									      v-model="folios.valor3"
									      row
									      dense
									      hide-details
									      readonly
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="contacto.concepto == 1">¿El asesor usa los documentos de la escuela de manera correcta (Plan de estudios, calendarios de pago , métodos de pago)?</label>
									    <v-radio-group
												v-if="contacto.concepto == 1"
									      v-model="folios.valor4"
									      row
									      dense
									      hide-details
									      readonly
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="contacto.concepto == 1">¿El asesor se dirige con formalidad al prospecto?</label>
									    <v-radio-group
												v-if="contacto.concepto == 1"
									      v-model="folios.valor5"
									      row
									      dense
									      hide-details
									      readonly
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="contacto.concepto == 2">¿Hay seguimientos Robotizados?</label>
									    <v-radio-group
												v-if="contacto.concepto == 2"
									      v-model="folios.valor6"
									      row
									      dense
									      hide-details
									      readonly
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="contacto.concepto == 2">¿El tiempo de respuesta entre el alumno y el asesor académico es adecuado? </label>
									    <v-radio-group
												v-if="contacto.concepto == 2"
									      v-model="folios.valor7"
									      row
									      dense
									      readonly
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="contacto.concepto == 2">¿El asesor resuelve las situaciones y dudas del alumno?</label>
									    <v-radio-group
												v-if="contacto.concepto == 2"
									      v-model="folios.valor8"
									      row
									      dense
									      readonly
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="contacto.concepto == 2">¿El asesor envia el recordatorio de pago en tiempo y forma?</label>
									    <v-radio-group
												v-if="contacto.concepto == 2"
									      v-model="folios.valor9"
									      row
									      dense
									      readonly
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>

											<label v-if="contacto.concepto == 2">¿El asesor se dijere de manera formal y educada con el alumno?</label>
									    <v-radio-group
												v-if="contacto.concepto == 2"
									      v-model="folios.valor10"
									      row
									      dense
									      readonly
									      hide-details
									      class="mt-0 mb-4"
									    >
									      <v-radio
									        label="SI"
									        :value="1"
									      ></v-radio>
									      <v-radio
									        label="NO"
									        :value="2"
									      ></v-radio>
									    </v-radio-group>
									    
											<v-textarea
												v-model="folios.retroalimentacion"
												:rows="6"
												auto-grow
												filled
												dense
												readonly
												label="Escribe una Retroalimentación"
											>
											</v-textarea>
										</v-col>
							    </v-row>
							  </v-card-text>
							</v-card>
						</v-col>
           
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Ver evidencias -->
    <v-dialog v-model="dialogImagen" max-width="1000px">
      <v-card class="elevation-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogImagen = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-img
            :src=" url + imagen" 
            contain
            max-height="80vh"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la información?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import leerImagenes          from '@/mixins/leerImagenes'

  import { Clipboard } from "@capacitor/clipboard";

  export default {

    mixins: [ validarErrores, funcionesExcel, leerImagenes ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,

      editedItem: {
        idretroalimentacion:0,
        p1:null,
        p2: null,
        p3:null,
        file: null,
        url:null,
				plantel:'',
				iniciales:'',
				calle:'',
				numero:0,
				colonia:'',
				estado:'',
				cp:0,
				telefono:'',
				link_maps:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
        capacidad:0,
        link_zoom:'',
        tel_principal:'',
        tel_secundario:'',
        wpp_ventas:'',
        wpp_sucursal:'',
      },

      defaultItem: {
        idretroalimentacion:0,
        p1:null,
        p2: null,
        p3:null,
        file: null,
        url:null,
				plantel:'',
				iniciales:'',
				calle:'',
				numero:0,
				colonia:'',
				estado:'',
				cp:0,
				telefono:'',
				link_maps:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
        capacidad:0,
        link_zoom:'',
        tel_principal:'',
        tel_secundario:'',
        wpp_ventas:'',
        wpp_sucursal:'',
      },

      // Filtros:
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      retros: [],
      headers: [
        { text: 'ID'        , value: 'idretroalimentacion' },
        { text: 'Evalua'    , value: 'evalua'              },
        { text: 'Evaluado'  , value: 'evaluado'            },
        { text: 'Promedio'  , value: 'promedioTexto'       },
        { text: 'Concepto'  , value: 'concepto'            },
        { text: 'Estatus'   , value: 'estatus'             },
        { text: 'Actions'   , value: 'actions', sortable: false },
      ],

      headersReporte: [
        { text: 'Escuela' , value: 'escuela' },
        { text: 'RI'      , value: 'ri'      },
        { text: 'NI'      , value: 'ni'      },
      ],

      ciclos:[],

      usuarios:[],

      editProspecto:{
      	idprospectos: 0,
      	folio: '',
      	telefono: '',
      	foto:'',
      	calificacion: 0,
      	valor1:null,
      	valor2:null,
      	valor3:null,
      	valor4:null,
      	valor5:null,
      	valor6:null,
      	valor7:null,
      	valor8:null,
      	valor9:null,
      	valor10:null,
      },

      contactos: [],
    	prospectos:[],
    	cantidadContactos: 0,
    	file: '',
      dragging: false,
      vistaPrevia: null,
      contacto:{},
      url: null,
      imagen: null,
			dialogImagen: false,

			concepto: 3,
			estatus: 3,

			fechaini:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      ciclo: null,
      dialogEdit: false,

      evaluados: [],
      evaluado: null,
      alumnos:[],
      alumno: null,

      acceso: false
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar retroalimentación' : 'Editar retroalimentación'
      },

      filterRetros( ){

      	let retros = this.retros

      	if( this.concepto != 3 ){  retros = retros.filter( el => el.concepto == this.concepto ) } 
      	if( this.estatus != 3 ){  retros = retros.filter( el => el.estatus == this.estatus ) } 
      	if( this.evaluado ){  retros = retros.filter( el => el.id_evaluado == this.evaluado ) } 
      	return retros

      },


      reporte( ){

      	let data = [

      		{
      			escuela: 'INBI',
      			ni: ((this.filterRetros.filter( el => el.escuela == 1 && el.concepto == 1 ).map(item => parseFloat( item.promedio )).reduce((prev, curr) => prev + curr, 0) / this.filterRetros.filter( el => el.escuela == 1 && el.concepto == 1 ).length )).toFixed( 1 ),
      			ri: ((this.filterRetros.filter( el => el.escuela == 1 && el.concepto == 2 ).map(item => parseFloat( item.promedio )).reduce((prev, curr) => prev + curr, 0) / this.filterRetros.filter( el => el.escuela == 1 && el.concepto == 2 ).length )).toFixed( 1 )
      		},

      		{
      			escuela: 'FAST',
      			ni: ((this.filterRetros.filter( el => el.escuela == 2 && el.concepto == 1 ).map(item => parseFloat( item.promedio )).reduce((prev, curr) => prev + curr, 0) / this.filterRetros.filter( el => el.escuela == 2 && el.concepto == 1 ).length )).toFixed( 1 ),
      			ri: ((this.filterRetros.filter( el => el.escuela == 2 && el.concepto == 2 ).map(item => parseFloat( item.promedio )).reduce((prev, curr) => prev + curr, 0) / this.filterRetros.filter( el => el.escuela == 2 && el.concepto == 2 ).length )).toFixed( 1 )
      		}

      	]



      	return data
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      ciclo( value ){
      	console.log( value )
      	this.fechafin = value ? value.fecha_fin_ciclo.substr( 0,10) : this.fechafin
      	this.fechaini = value ? value.fecha_inicio_ciclo.substr( 0,10) : this.fechaini

      	if( value ){
      		this.initialize( )
      	}
      }

    },

    async created () {
    	this.url = axios.defaults.baseURL  + 'imagenes-retro/'
      await this.getAcceso()
      await this.initialize()
      await this.getCiclosActivos( )
      await this.getAlumnos( )
      await this.getUsuarios()
      await this.getProspectos( )

      this.contactos = [
      	{
        	idprospectos: 0,
	      	folio: '',
	      	foto:'',
	      	retroalimentacion:null,
					folio:null,
					telefono:null,
					valor1:null,
					valor2:null,
					valor3:null,
					valor4:null,
					valor5:null,
					valor6:null,
					valor7:null,
					valor8:null,
					valor9:null,
					valor10:null,
					file:null,
					url:null,
        },
        {
        	idprospectos: 0,
	      	folio: '',
	      	foto:'',
	      	retroalimentacion:null,
					folio:null,
					telefono:null,
					valor1:null,
					valor2:null,
					valor3:null,
					valor4:null,
					valor5:null,
					valor6:null,
					valor7:null,
					valor8:null,
					valor9:null,
					valor10:null,
					file:null,
					url:null,
        },
        {
        	idprospectos: 0,
	      	folio: '',
	      	foto:'',
	      	retroalimentacion:null,
					folio:null,
					telefono:null,
					valor1:null,
					valor2:null,
					valor3:null,
					valor4:null,
					valor5:null,
					valor6:null,
					valor7:null,
					valor8:null,
					valor9:null,
					valor10:null,
					file:null,
					url:null,
        }
      ]
    },

    methods: {

    	getAcceso() {
      	this.cargar = true
        this.retros = []

        return this.$http.get('retroalimentacion.acceso.alta/' + this.getdatosUsuario ).then(response=>{
        	this.acceso = response.data.acceso

        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      initialize () {
      	this.cargar = true
        this.retros = []

        const payload = {
        	fechafin  : this.fechafin,
					fechaini  : this.fechaini,
					id_usuario: this.getdatosUsuario.iderp
        }

        return this.$http.post('retroalimentacion.personal.all', payload ).then(response=>{
        	this.retros = response.data

        	this.evaluados = this.retros.reduce((acumulador, actual) => {
					  // Verifica si el id_evaluado ya existe en el acumulador
					  if (!acumulador.some(item => item.id_evaluado === actual.id_evaluado)) {
					    acumulador.push(actual);
					  }
					  return acumulador;
					}, []);

        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuarios( ){
      	this.usuarios = []
    		this.$http.get('usuarios.all.erp').then(response=>{
    			for(const i in response.data){
    				response.data[i].datos_completos = response.data[i].datos_completos.toUpperCase()
    			}
    			this.usuarios = response.data
    		}).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getProspectos( ){
    		this.cargar    = true
      	this.prospectos = []

        return this.$http.get('inscripciones.prospectos').then(response=>{
        	this.prospectos = response.data.filter( el => el.finalizo == 0  )
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

    	getAlumnos( ){
    		this.cargar    = true
      	this.alumnos = []
        return this.$http.get('inscripciones.alumnos').then(response=>{
        	this.cargar = false
        	this.alumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

    	getCiclosActivos(){
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          this.ciclos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.retros.indexOf(item)
        this.contacto    = Object.assign({}, item)
        this.dialogEdit = true
      },

      deleteItem (item) {
        this.editedIndex = this.retros.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {

      	const payload = {
          idretroalimentacion: this.editedItem.idretroalimentacion,
          mensaje_rechazo: this.editedItem.mensaje_rechazo, 
          estatus: this.editedItem.estatus,
          deleted: 1
        }

        this.$http.post('retroalimentacion.update', payload ).then(response=>{
          this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      close () {
        this.dialog = false
        this.dialogEdit = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      updateFoto( contacto ){

      	// Verificar que el archivo existe
				if (!contacto.file) {
			    this.validarErrorDirecto("No se ha seleccionado un archivo.");
			    return;
				}

      	// Crear una instancia de FormData
		    let formData = new FormData();
		    formData.append('file', contacto.file);

        // Crear vista previa utilizando FileReader
		    const reader = new FileReader();
		    reader.readAsDataURL(contacto.file); // Acceder correctamente al archivo desde contacto.file

        reader.onload = () => {
          contacto.url = reader.result
          contacto.formData = formData 
          contacto.image_name = contacto.file.name
        };
      },

      updateFoto2( ){

      	// Verificar que el archivo existe
				if (!this.editedItem.file) {
			    this.validarErrorDirecto("No se ha seleccionado un archivo.");
			    return;
				}

      	// Crear una instancia de FormData
		    let formData = new FormData();
		    formData.append('file', this.editedItem.file);

        // Crear vista previa utilizando FileReader
		    const reader = new FileReader();
		    reader.readAsDataURL(this.editedItem.file); // Acceder correctamente al archivo desde this.editedItem.file

        reader.onload = () => {
          this.editedItem.url = reader.result
          this.editedItem.formData = formData 
          this.editedItem.image_name = this.editedItem.file.name
        };


      },

      addCantidad () {

      	if( this.cantidadContactos < 5 ){
	        this.cantidadContactos += 1
	        this.contactos.push({
	        	idprospectos: 0,
		      	folio: '',
		      	telefono: '',
		      	foto:'',
		      	retroalimentacion:null,
						folio:null,
						telefono:null,
						valor1:null,
						valor2:null,
						valor3:null,
						valor4:null,
						valor5:null,
						valor6:null,
						valor7:null,
						valor8:null,
						valor9:null,
						valor10:null,
						id_alumno: null,
	        })
      	}
      },

      menosCantidad () {
        if( this.cantidadContactos > 1 ){ 
        	this.cantidadContactos -= 1 
        	this.contactos.pop()
        }
      },

      save () {

      	for( const i in this.contactos ){

      		const { idprospectos, telefono } = this.contactos[i]

      		const existeContacto = this.prospectos.find( el => el.idprospectos == idprospectos )

      		this.contactos[i]['folio']    = existeContacto ? existeContacto.folio : ''
      		this.contactos[i]['telefono'] = existeContacto ? existeContacto.telefono : telefono

      	}

      	const existeUsuario = this.usuarios.find( el => el.id_usuario == this.editedItem.id_usuario )

      	const payload = {
      		id_evalua    : this.getdatosUsuario.iderp,
      		id_evaluado  : this.editedItem.id_usuario,
      		concepto     : this.editedItem.concepto,

      		p1           : this.editedItem.p1,
					p2           : this.editedItem.p2,
					p3           : this.editedItem.p3,
					calificacion : this.editedItem.calificacion,

      		contactos  : this.contactos,
      		escuela    : existeUsuario ? existeUsuario.escuela : 0
      	}


      	let formData = new FormData();

      	// Agregar datos principales al FormData
				formData.append("id_evalua"      , payload.id_evalua);
				formData.append("id_evaluado"    , payload.id_evaluado);
				formData.append("concepto"       , payload.concepto);
				formData.append("escuela"        , payload.escuela);
				formData.append("p1"             , payload.p1);
				formData.append("p2"             , payload.p2);
				formData.append("p3"             , payload.p3);
				formData.append("calificacion"   , payload.calificacion);
    		formData.append("imagenAdicional", this.editedItem.file);

				// Agregar los contactos al FormData
				payload.contactos.forEach((contacto, index) => {
			    // Agregar la información del contacto
			    formData.append(`contactos[${index}]`, JSON.stringify({
		        calificacion     : contacto.calificacion,
		        idprospectos     : contacto.idprospectos,
		        retroalimentacion: contacto.retroalimentacion,
		        folio            : contacto.folio,
		        telefono         : contacto.telefono,
		        valor1           : contacto.valor1 ? contacto.valor1 : 0,
		        valor2           : contacto.valor2 ? contacto.valor2 : 0,
		        valor3           : contacto.valor3 ? contacto.valor3 : 0,
		        valor4           : contacto.valor4 ? contacto.valor4 : 0,
		        valor5           : contacto.valor5 ? contacto.valor5 : 0,
		        valor6           : contacto.valor6 ? contacto.valor6 : 0,
		        valor7           : contacto.valor7 ? contacto.valor7 : 0,
		        valor8           : contacto.valor8 ? contacto.valor8 : 0,
		        valor9           : contacto.valor9 ? contacto.valor9 : 0,
		        valor10          : contacto.valor10 ? contacto.valor10 : 0,
		        id_alumno        : contacto.id_alumno ? contacto.id_alumno : 0,
			    }));

			    // Agregar el archivo si existe
			    if (contacto.file) {
		        formData.append(`contactos[${index}][file]`, contacto.file);
			    }
				});

        this.$http.post('retroalimentacion.personal', formData ).then(response=>{
        	this.contactos = [
		      	{
		        	idprospectos: 0,
			      	folio: '',
			      	foto:'',
			      	retroalimentacion:null,
							folio:null,
							telefono:null,
							valor1:null,
							valor2:null,
							valor3:null,
							valor4:null,
							valor5:null,
							valor6:null,
							valor7:null,
							valor8:null,
							valor9:null,
							valor10:null,
							file:null,
							url:null,
		        },
		        {
		        	idprospectos: 0,
			      	folio: '',
			      	foto:'',
			      	retroalimentacion:null,
							folio:null,
							telefono:null,
							valor1:null,
							valor2:null,
							valor3:null,
							valor4:null,
							valor5:null,
							valor6:null,
							valor7:null,
							valor8:null,
							valor9:null,
							valor10:null,
							file:null,
							url:null,
		        },
		        {
		        	idprospectos: 0,
			      	folio: '',
			      	foto:'',
			      	retroalimentacion:null,
							folio:null,
							telefono:null,
							valor1:null,
							valor2:null,
							valor3:null,
							valor4:null,
							valor5:null,
							valor6:null,
							valor7:null,
							valor8:null,
							valor9:null,
							valor10:null,
							file:null,
							url:null,
		        }
		      ]
        	this.cantidadContactos = 0
        	this.initialize()
        	this.cargar = false
      		this.close()
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })


        // }
      },

      async pegar(event) {
        if (event.code == "KeyV") {
          const { type, value } = await Clipboard.read();

                    // Validamos que sea de tipo imagen
          if (type == "image/png") {
                    // Convertimos el base64 en una imagen
            const file = this.convertFile(value, "imagen.png");
            this.createFile(file);
          }
        }
      },

      convertFile(dataurl, filename) {
        var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      },

      createFile(file) {
        if (file.size > 10000000) {
          alert("please check file size no over 5 MB.");
          return;
        }

        console.log( 'archivooooo',file )

        if( !this.editedItem.file ){
        	
        	this.editedItem.file = file
        	this.updateFoto2( )

        }else if( !this.contactos[0].file ){
        	this.contactos[0].file = file
        	this.updateFoto( this.contactos[0] )
        }else if( !this.contactos[1].file ){
        	this.contactos[1].file = file
        	this.updateFoto( this.contactos[1] )
        }else if( !this.contactos[2].file ){
        	this.contactos[2].file = file
        	this.updateFoto( this.contactos[2] )
        }


      },

      verImagen( imagen ){
        this.imagen       = imagen
        this.dialogImagen = true
      },
    },
  }
</script>


<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>